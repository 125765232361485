<template>
  <div>
    <common-header></common-header>
    <loading v-if="loading"></loading>
    <div v-if="nodata">
      <no-data style="margin-top:.5rem" txt="你的购物车空空如也~"></no-data>
      <router-link to="/" class="go-index jb-r-l">去首页逛逛吧</router-link>
    
    </div>
    <div v-else>
      <div class="car-list-box warp">
        <div class="shopcar-admin" ref="admin">
          <div class="l"  @click="adminShopCar" >
            <i class="icon-shopcar-admin"></i>{{delBtnShow ? '完成' : '编辑购物车'}}
          </div>
          <!-- <div class="" v-if="delBtnShow" @click="deleteSxGoods">删除失效商品</div> -->
        </div>
        <ul class="" v-if="lists.length > 0" >
          <li class="item" :class="item.inventory == 0 && !delBtnShow ? 'invalid' : ''" v-for="(item, index) in lists" :key="index">
            <div class="check" v-if="delBtnShow" :class="item.check ? 'active' : ''" @click="choiceCommodity(index)"></div>
            <div class="check" v-else :class="item.check && item.inventory > 0 ? 'active' : ''" @click="choiceCommodity(index)"></div>
            <img class="goods-img" :src="noImgSet(item.image)" alt="">
            <!-- <img class="goods-img" v-if="item.image != '[]'" :src="requestImgUrl($transObjUndefined(JSON.parse(item.image)[0]).url)" alt=""> -->
            <div class="content">
              <div class="name">{{item.name}}</div>
              <!-- <div class="ms" v-if="!item.seckill && item.minLimit == 1 && !item.tags">{{item.description}} </div> -->
              <div class="tags-box">
                <div class="tag m-kill" v-if="item.specName">{{item.specName}}</div>
                <div class="tag m-kill" v-if="item.seckill">秒杀</div>
                <template v-if="item.tags">
                  <div class="tag tags-item"  v-for="(tagItem, tagIndex) in (item.tags).split(',')" :key="tagIndex">
                    {{tagItem}}
                  </div>
                </template>
                <div class="tag buy-from" v-if="item.minLimit > 1">{{item.minLimit}}{{item.unitName}}起购</div>
              </div>
              <div class="price-box">
                <div class="is-certified" v-if="(Number(item.price)) < 0">{{isCertified()}}</div>
                <div class="price" v-else>
                  <span class="unit">￥</span>{{item.seckill ? item.seckill.seckillPrice + item.deposit : item.price + item.deposit}}
                  <div class="deposit" v-if="item.deposit > 0">(含押金{{item.deposit}}元)</div>
                </div>
                <div class="number-box">
                  <div class="reduce btn" @click="delNum(item.commodityId, item.specId, index, item.minLimit)">-</div>
                  <div class="val">{{ item.inventory > 0 ? item.quantity : 0}}</div>
                  <div class="add btn" @click="addNum(item.commodityId, item.specId, index)">+</div>
                </div>
              </div>
            </div>
          </li>
        </ul>

      </div>

      <div class="settlement-box" ref="settlement">
        <div class="l" @click="selectAllFun">
          <div class="check" :class="selectAll ? 'active' : ''"></div>
          <div class="txt">全选</div>
        </div>
        <div class="r">
          <div class="is-certified" v-if="!delBtnShow && (amountData.totalPrice && amountData.totalPrice < 0)">{{isCertified()}}</div>
          <div class="total" v-if="!delBtnShow && (amountData.totalPrice && amountData.totalPrice > 0)">共计<span class="money"><i class="unit">￥</i>{{amountData.totalPrice ? ((amountData.totalPrice - (amountData.freightPrice > 0 ? amountData.freightPrice : 0)).toFixed(2)) : 0}}</span></div>
          <div class="del-btn" v-if="delBtnShow" @click="deleteGoods">删除<span v-if="count > 0">({{count}})</span></div>
          <div @click="amountData.totalPrice < 0 ? '' : placeOrder()" class="go-settlement" :class="amountData.totalPrice < 0 ? 'hui' : ''" v-else-if="orderCommodity.length > 0">去结算</div>
        </div>
      </div>
    </div>

    <div class="recommend-commodity" v-if="commodityList.length > 0">
      <div class="title">
        <span>为你推荐</span>
      </div>
      <waterfall :col='2' :data="commodityList" class="goods-ul">
        <template>
          <li class="goods-li" v-for="(item, index) in commodityList" :key="index">
            <div class="item">
              <div class="top" @click="$router.push({path:'/shopping/details', query: {id: item.id}})">
                <div class="good-imgbox pre">
                  <img class="good-img" :key="requestImgUrl($transObjUndefined(JSON.parse(item.thumbnail)[0]).url)" alt="" v-lazy="requestImgUrl($transObjUndefined(JSON.parse(item.thumbnail)[0]).url)">
                  <span class="quehuo" v-if="item.specList && item.specList.length > 0 && item.specList[0].inventory === 0">缺货</span>
                </div>
                <div class="good-name">{{item.name}}</div>
                <!-- 月销 start -->
                <div class="month-sales" v-if="(item.monthlySales + item.virtualMonthlySales) > 1000">月销量{{item.monthlySales + item.virtualMonthlySales}}</div>
                <!-- 月销 end -->
                <div class="no-unitName" v-if="item.specList[0].unitPrice > 0 && item.unitName != item.specList[0].minUnitName && item.specList[0].unitQuantity > 1">￥{{(Number(item.specList[0].price) + item.specList[0].deposit)}}{{item.specList[0].unitName!=''? '/'+item.specList[0].unitName:(item.unitName != '' ? `/${item.unitName}` : '')}}</div>
              </div>
              <div class="bottom">
                <div class="is-certified" v-if="item.specList[0].unitPrice < 0 ">{{isCertified()}}</div>
                <div class="price-box" v-else>
                  <div class="present-price">
                    <div class="unit">￥</div>
                    <div class="moeny">
                      {{item.specList[0].unitPrice}}<span class="unit">{{item.specList[0].minUnitName != '' ? '/'+item.specList[0].minUnitName : ''}}</span>
                    </div>
                  </div>
                  <!-- <div class="org-price">￥88</div> -->
                </div>

                <div class="specs-btn-box jb-r-l" v-if="item.specList && item.specList.length > 1" @click.stop="$router.push({path:'/shopping/details', query: {id: item.id}})" >{{ item.specsShow ? "收起" : "规格" }}</div>
                <div class="icon-car-btn" v-else @click.stop=" SelectProducts(item.id,item.specList[0].id,item.inventory)"></div>

              </div>
            </div>
          </li>
        </template>
      </waterfall>
    </div>

    <tabBar ref="tabbar"></tabBar>
  </div>
</template>
<script>
import { mapActions, mapState, mapMutations  } from 'vuex';
import { requestImgUrl, isWeiXin, funcUrlDel, noImgSet, authFun} from "@/utils/common";
import { isHttp, isCertified} from "@/utils/utils";
export default {
  data() {
    this.requestImgUrl = (url, privates) => requestImgUrl(url, privates);
    this.noImgSet = (url) => noImgSet(url);
    this.isCertified = () => isCertified();
    return {
      delBtnShow: false,
      uid: localStorage.getItem('uid'),
      lists: [],
      commodityList: [],
      // 已选商品
      activeArrs: [],
      // 计算的价格对象
      amountData: {},
      // 内容高度
      centerHeight: 0,
      // 全选
      selectAll: false,
      // 暂无数据
      nodata: false,
      // 用户信息
      cook: this.$Cookies.get("user"),
      // 商品总数
      count: 0,
      // 已选的商品
      orderCommodity: [],
      loading: true,
      userInfo: ''
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    // 选择商品事件
    SelectProducts(commodityId, specId, inventory) {
      if (inventory <= 0) {
        this.$toast("卖光啦！");
      }
      this.AddCommodityCart({
        commodityId: commodityId,
        specId: specId,
        uid: this.uid
      }).then(res => {
        if (res.status == 200) {
          this.$toast("加入购物车成功");
          this.init();
          // 获取购物车总数
          this.getCommodityCount({
            uid: this.uid
          }).then(res => {
            this.goodsNumMuta(res.data.quantity);
          });
        }
      });
    },
    // 为你推荐
    getRecommend() {
      this.GetCommodityList({
        habitRecommend: 1,
      }).then((res)=> {
        if(res.data.list.length>0){
          this.commodityList = res.data.list;
        }else{
          this.GetCommodityList({
            recommend: 1,
          }).then((res)=> {
              this.commodityList = res.data.list;
          })
        }
      })
    },
    // 初始化
    init() {
      this.getRecommend();
      this.getCommodityCart({
        uid: this.uid,
      }).then((res)=> {
        let lists = []
        let da = res.data;
        da.forEach(e => {
          e.check = true;
          lists.push(e);
        });
        this.lists = lists;
        this.goodsNumMuta(lists.length);
        this.totalPrice();
        this.loading = false;
        // 计算内容区高度
        // if (this.goodsNum > 0) {
        //   setTimeout(()=> {
        //     this.jsHeight()
        //   },100)
        // }
      })

      if (localStorage.getItem('userInfo')) {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
      } else {
        this.getUserInfo().then((res)=> {
          this.userInfo = res.data;
          localStorage.setItem('userInfo', JSON.stringify(res.data))
        })
      }
    },
    // 批量删除
    deleteGoods() {
      this.$MessageBox({
        title: '温馨提示',
        message: `确认要删除这${this.activeArrs.length}种商品?`,
        showCancelButton: true
      }).then((res)=> {
        if (res == 'confirm') {
          this.DeleteCarBatch({
            detail: JSON.stringify(this.activeArrs),
            uid: this.uid,
          }).then((res)=> {
            this.lists = [];
            this.init();
          })
        }
      })
    },
    
    // 全选事件
    selectAllFun() {
      let selectAll = !this.selectAll;
      let arrs = [];
      this.lists.forEach((e)=> {
        e.check = selectAll;
        arrs.push(e);
      });
      this.lists = arrs;
      this.totalPrice();
    },
    // 下单
    placeOrder() {
      if (this.cook) {
        if (this.userInfo.hasMpOpenId) {
          this.$router.push({
            path: '/shopping/confirmorder',
          })
        } else {
          // 绑定微信
          const u = window.location.origin + this.$route.fullPath + "?bind=wx";
          authFun(u);
        }
      } else {
        this.$MessageBox({
          title: "温馨提示",
          message: "您还未登录，请先登录",
          confirmButtonText: "去登录",
          showCancelButton: true,
        }).then((a)=> {
          if (a == 'confirm') {
            const u = window.location.pathname;
            this.$router.push({
              path: `/guide/login?historyPath=${encodeURIComponent(u)}`
            })
          }
        })
      }
    },
    // 取消勾选
    choiceCommodity(index) {
      let { lists } = this;
      lists[index].check = !lists[index].check;
      this.lists = lists;
      this.totalPrice();
    },
    adminShopCar() {
      this.delBtnShow = !this.delBtnShow;
      this.totalPrice();
    },
    // 添加数量
    addNum(commodityId,specId, index) {
      this.AddCommodityCart({
        commodityId: commodityId,
        specId: specId,
        uid: this.uid
      }).then((res)=> {
        if (res.status == 200) {
          let { lists } = this;
          lists[index].quantity = lists[index].quantity + 1;
          this.lists = lists;
          this.totalPrice();
        }
      })
    },
    // 减去数量
    delNum(commodityId,specId, index, minLimit) {
      let { lists } = this;
      let quantity = lists[index].quantity - 1;
      if (minLimit > quantity || quantity < 1) {
        this.$MessageBox({
          title: "删除商品",
          message: "确定删除该商品吗",
          showCancelButton: true,
        }).then((a)=> {
          if (a == 'confirm') {
             this.delCommodity(commodityId,specId, index, 'del');
          }
        })
        return false;
      }
      this.delCommodity(commodityId,specId, index);
    },
    //删除失效商品
    deleteSxGoods() {},
    // 删除商品
    delCommodity(commodityId,specId, index, del) {
      this.DeleteCommodity({
        commodityId: commodityId,
        specId: specId,
        uid: this.uid
      }).then((res)=> {
        if (res.status == 200) {
          let { lists } = this;
          if (del) {
            lists.splice(index, 1);
            this.lists = lists;
            this.goodsNumMuta(this.lists.length);
          } else {
            lists[index].quantity = lists[index].quantity - 1;
            this.lists = lists;
          }
          this.totalPrice();
        }
      })
    },
    // 计算总价
    totalPrice() {
      let arrs = [];
      // 已选的商品
      let orderCommodity = [];
      // 计数值
      let count = 0;
      // 可购买的商品数量
      let CanBuyNum = 0;
      this.lists.forEach((e)=> {
        if (!this.delBtnShow) {
          if (e.check && e.inventory > 0) {
            arrs.push({
              id: e.commodityId,
              quantity: e.quantity,
              specId: e.specId,
            });
            count++;
            orderCommodity.push(e);
          }
        } else {
          if (e.check) {
            arrs.push({
              id: e.commodityId,
              quantity: e.quantity,
              specId: e.specId,
            });
            count++;
          }
        }
        
        if (e.inventory > 0) {
          CanBuyNum++;
        }
      });
      this.nodata = this.lists.length > 0 ? false : true;
      this.count = count;
      this.activeArrs = arrs;
      // 判断计数值是否等于商品数量，等于就全选
      if (!this.delBtnShow) {
        if (count > 0 && count == CanBuyNum) {
          this.selectAll = true;
        } else {
          this.selectAll = false
        }
      } else {
        if (count > 0 && count == this.lists.length) {
          this.selectAll = true;
        } else {
          this.selectAll = false
        }
      }
      this.orderCommodity = orderCommodity;
      // 缓存商品信息，用户下单使用
      localStorage.setItem("orderCommodity", JSON.stringify(orderCommodity))
      this.CountAmount({
        detail: JSON.stringify(arrs),
      }).then((res)=> {
        this.amountData = res.data;
      })
    },
    jsHeight() {
      let innerHeight = window.innerHeight;
      const headerHeight = isWeiXin() ? 0 : 40;
      const tabberHeight = this.$refs.tabbar.$el.offsetHeight;
      const adminHeight = this.$refs.admin.offsetHeight;
      const settlementHeight = this.$refs.settlement.offsetHeight;
      let height = innerHeight - headerHeight - tabberHeight - adminHeight - settlementHeight - 15;
      this.centerHeight = height;
    },
    ...mapActions('shopping', [
      "getCommodityCart",
      "CountAmount",
      "AddCommodityCart",
      "DeleteCommodity",
      "DeleteCarBatch",
      "GetCommodityList",
      'getCommodityCount',
    ]),
    ...mapActions('account', [
      'getUserInfo',
    ]),
    ...mapMutations(["goodsNumMuta"])
  },
  computed: {
    ...mapState(["goodsNum"]),
  },
}
</script>
<style lang='less' scoped>
.deposit{
  color: #ccc;
  font-size: .24rem;
  margin-left: .15rem;
}
.month-sales{
  margin-top: -0.12rem;
}
.month-sales, .no-unitName{
  padding: 0 0.25rem;
  font-size: .25rem;
  color: #ccc;
  line-height: 1;
}
.no-unitName{
  margin: 0.2rem 0 -0.02rem 0;
}
.goods-ul {
  padding: 0 .1rem;
  margin-top: .4rem;
  .present-price {
    display: flex;
    align-items: flex-end;
    color: #ff7241;
    .unit {
      font-size: 0.24rem;
    }
    .moeny {
      font-size: 0.32rem;
    }
  }
  .goods-li {
    padding: 0 0.1rem;
    margin-bottom: 0.2rem;

    .item {
      background: #fff;
      border-radius: 0.25rem;
      overflow: hidden;
      box-shadow: 0 0 0.1rem #efefef;
      padding-bottom: 0.25rem;
      .good-img {
        height: 3.45rem;
      }
    }

    .quehuo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 5;
      background-color: rgba(167, 167, 167, 0.7);
      color: #fff;
      width: 1.4rem;
      height: 1.4rem;
      font-size: 0.32rem;
      text-align: center;
      line-height: 1.4rem;
      display: block;
      border-radius: 1rem;
    }

    .good-name {
      font-size: 0.26rem;
      color: #212121;
      padding: 0 0.25rem;
      margin-top: 0.2rem;
      margin-bottom: 0.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .bottom {
      padding: 0 0.25rem;
      display: flex;
      justify-content: space-between;
    }
  }
}
.recommend-commodity{
  margin-top: 1.5rem;
  padding-bottom: 1.5rem;
  .title{
    font-size: .3rem;
    text-align: center;
    color: #ff7141;
    span{
      position: relative;
      &::after{
        position: absolute;
        content: "";
        left: -.2rem;
        top: 50%;
        width: 1rem;
        height: 1px;
        background: #ff7141;
        transform: translateX(-100%);
        margin-top: -0.5px;
      }
      &::before{
        position: absolute;
        content: "";
        right: -.2rem;
        top: 50%;
        width: 1rem;
        height: 1px;
        background: #ff7141;
        transform: translateX(100%);
        margin-top: -0.5px;
      }
    }
  }
}
.go-index{
  width: 3.4rem;
  height: .65rem;
  border-radius: .32rem;
  display: block;
  margin: auto;
  color: #fff;
  text-align: center;
  line-height: .65rem;
  margin-top: .28rem;
}
.clear-invalid{
  font-size: .3rem;
  color: #727272;
  width: 3.4rem;
  height: .65rem;
  background: #fff;
  text-align: center;
  line-height: .65rem;
  border-radius: .32rem;
  margin: .4rem auto 0;
}
.settlement-box{
  position: fixed;
  height: 1.2rem;
  width: 100%;
  background: #ffffff;
  bottom: 1.1rem;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 .2rem 0 .35rem;
  z-index: 99999;
  .is-certified{
    margin-top: 0;
    margin-right: .2rem;
  }
  .total{
    margin-right: .3rem;
  }
  .del-btn{
    width: 2.4rem;
    height: .78rem;
    border: .02rem solid #ff7241;
    color: #ff7241;
    text-align: center;
    line-height: .78rem;
    border-radius: .4rem;
    font-size: .32rem;
  }
  .go-settlement{
    &.hui{
      background: #ccc;
    }
    width: 2.4rem;
    height: .78rem;
    border-radius: .4rem;
    color: #fff;
    font-size: .32rem;
    line-height: .78rem;
    text-align: center;
    font-weight: bold;
    background: rgba(255,113,65,1);/* Old Browsers */
    background: -moz-linear-gradient(left, rgba(255,113,65,1) 0%, rgba(255,185,139,1) 100%); /* FF3.6+ */
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,113,65,1)), color-stop(100%, rgba(255,185,139,1)));/* Chrome, Safari4+ */
    background: -webkit-linear-gradient(left, rgba(255,113,65,1) 0%, rgba(255,185,139,1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left, rgba(255,113,65,1) 0%, rgba(255,185,139,1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(left, rgba(255,113,65,1) 0%, rgba(255,185,139,1) 100%); /* IE 10+ */
    background: linear-gradient(to right, rgba(255,113,65,1) 0%, rgba(255,185,139,1) 100%);/* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7141', endColorstr='#ffb98b', GradientType=1 );/* IE6-9 */
  }
  .r{
    color: #262626;
    font-size: .26rem;
    display: flex;
    align-items: center;
    .money{
      font-size: .32rem;
      color: #ff7241;
      margin-left: .08rem;
    }
    .unit{
      font-size: .24rem;
    }
  }
  .l{
    display: flex;
    align-items: center;
    .txt{
      color: #262626;
      font-size: .26rem;
    }
  }
}
.check{
  width: .38rem;
  height: .38rem;
  border-radius: 50%;
  border: .02rem solid #d6d6d6;
  margin-right: .15rem;
  &.active{
    background:url('../../assets/images/img/check-active.png') no-repeat;
    background-size: 100%;
    border: none;
  }
}
.car-list-box{
  background:url('../../assets/images/img/car-bj.png') no-repeat;
  background-size: 100%;
  min-height: 2.5rem;
  flex-direction: column;
  ul{
    flex: 1;
    overflow-y: scroll;
  }
  .shopcar-admin{
    color: #fff;
    font-size: .3rem;
    padding-top: .4rem;
    padding-bottom: .3rem;
    padding-left: .3rem;
    height: 1.1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .l{
      display: flex;
      align-items: center;
    }
    i{
      margin-right: .1rem;
    }
  }
  .number-box{
    display: flex;
    border: 1px solid #ccc;
    line-height: .48rem;
    text-align: center;
    border-radius: .05rem;
    .btn{
      width: .48rem;
      height: .48rem;
      background: #f7f7f7;
    }
    .val{
      width: .56rem;
    }
  }
  .content{
    flex: 1;
    .name{
      font-size: .3rem;
      color: #262626;
      font-weight: bold;
    }
    .ms{
      font-size: .26rem;
      color: #bfbfbf;
      margin-top: .1rem;
    }
  }
  .price-box{
    display: flex;
    justify-content: space-between;
    margin-top: .1rem;
    .price{
      display: flex;
      color: #ff7241;
      font-size: .32rem;
      align-items: center;
      .unit{
        font-size: .24rem;
        margin-top: .1rem;
      }
    }
  }
  .item{
    background: #fff;
    border-radius: .1rem;
    display: flex;
    align-items: center;
    padding: .3rem .15rem;
    overflow: hidden;
    margin-bottom: .2rem;
    &:last-child{
      margin-bottom: 0;
    }
    &.invalid{
      position: relative;
      &::after{
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .2);
      }
      &::before{
        content: "";
        background:url('../../assets/images/img/selling.png') no-repeat;
        width: 1.06rem;
        height: 1.06rem;
        position: absolute;
        top: .6rem;
        left: .9rem;
        z-index: 2;
        background-size: 100%;
      }
    }
    .goods-img{
      position: relative;
      width: 1.52rem;
      height: 1.52rem;
      border-radius: .05rem;
      margin-right: .2rem;
    }
    
  }
}
.specs-btn-box{
  color: #fff;
  font-size: .24rem;
  padding: .1rem;
  border-radius: .1rem;
  display: flex;
}
</style>